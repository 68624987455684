export let projectData = [
  {
    imageLink: "https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExZzl4aGJ3bG5leXlwamNxYnc3NG00dDlia2RwNjYwa21zMXplcGI5dyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/36tIvfgLZXBrWcDhQg/giphy.gif",
    title: "Story Sprouts",
    body: "Designed and developed a personalized children's story generator app for iPad and iOS devices.",
    gitLink: "",
    liveLink: "https://storysproutsapp.com/",
  },
  {
    imageLink: "https://i.imgur.com/srCVXXX.gif",
    title: "Dealmed AI Assistant",
    body: "Designed and built an AI Assistant to help customers seamlessly access account information",
    gitLink: "",
    liveLink: "https://i.imgur.com/AHdd31M.mp4",
  },
  {
    imageLink: "https://media1.giphy.com/media/RRJJvXJcx2mnnpF47U/giphy.gif",
    title: "Dealmed Tracking",
    body: "Designed and developed an order tracking system, processing 2000 orders monthly with real-time updates.",
    gitLink: "",
    liveLink: "https://i.imgur.com/65KDxe5.mp4",
  },
  {
    imageLink: "https://media1.giphy.com/media/CX8q2cGB4k97tsbwPk/giphy.gif?cid=790b76119fefa548b85fd19a4b78ca2d85b58abd2c99e678&rid=giphy.gif",
    title: "WAX Login",
    body: "Created Web3 React component that integrates with the WAX blockchain to log-in along with surrounding components. ",
    gitLink: "",
    liveLink: "",
  },
  // {
  //   imageLink: "https://i.imgur.com/foxrUiW.png",
  //   title: "Tweet Tweet",
  //   body: "Tweet showcase app using React, Routing, Express, and Twitter's API to display and search Tweets.",
  //   gitLink: "https://github.com/KevinWestNYC/TwitterApp",
  //   liveLink: "https://kw-twitter-gallery-up7ev.ondigitalocean.app/",
  // },    
  {
    imageLink: "https://i.imgur.com/IilAkFL.gif",
    title: "Chit-Chat",
    body: "Chat App using React, and web-sockets to send messages in a chatroom.",
    gitLink: "https://github.com/KevinWestNYC/chat-app",
    liveLink: "https://kw-chat-app-ql742.ondigitalocean.app/",
  },
  {
    imageLink: "https://i.imgur.com/SC6P6u8.gif",
    title: "Snake",
    body: "Classic Snake game made using JavaScript and html canvas. (Desktop Only)",
    gitLink: "https://github.com/KevinWestNYC/Snake",
    liveLink: "https://kevinwestnyc.github.io/Snake/",
  },
];