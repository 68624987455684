export const links = [
    {
      id: 1,
      text: 'home',
      url: '#home',
    },
    {
      id: 2,
      text: 'about',
      url: '#about',
    },
    {
      id: 3,
      text: 'projects',
      url: '#projects',
    },
    {
      id: 4,
      text: 'contact',
      url: '#contact',
    },
  ]